import CatalogDashboard from './pages/catalogs';
import EventInspectorDashboard from './pages/event_inspector';
import RecommendersDashboard from './pages/recommenders';
import MetricsHubDashboard from './pages/metrics_hub';

export const pages = {
  catalog_dashboard: CatalogDashboard,
  event_inspector: EventInspectorDashboard,
  recommender_dashboard: RecommendersDashboard,
  metrics_hub: MetricsHubDashboard,
};

export default {
  pages,
};
